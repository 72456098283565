/* Color Scheme Stuff */
.light-theme {
	--bg: #fff;
	--border: #dfdfdf;
	--card-header: #f7f7f7;
	--card-body: #ffffff;
	--jumbotron-body: #f7f7f7;

	--text: #212529;
	--text-secondary: #6c757d;

	--header-text: #181a1c;
	--header-text-hover: #6b6b6b;
	--header-text-secondary: #3d4245;
	--header-text-secondary-hover: #797979;

	--accent: #007bff;
	--accent-hover: #0069d9;
	--accent-disabled: #5aaaff;
	--accent-text: #ffffff;

	--button: #fff;
	--button-hover: #e9ecef;

	--field-bg: #e9ecef;
	--field-bg-disabled: #e9ecef;
	--field-bg-focus: #fff;
	--field-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
}

.dark-theme {
	--bg: #1e1e1e;
	--border: #414141;
	--card-header: #323233;
	--card-body: #252526;
	--jumbotron-body: #252526;

	--text: #ffffff;
	--text-secondary: #828282;

	--header-text: #ffffff;
	--header-text-hover: #ffffff;
	--header-text-secondary: #ffffff;
	--header-text-secondary-hover: #cccecf;

	--accent: #007bff;
	--accent-hover: #0069d9;
	--accent-disabled: #5aaaff;
	--accent-text: #ffffff;

	--button: #323233;
	--button-hover: #282829;

	--field-bg: #3c3c3c;
	--field-bg-disabled: #1F1F1F;
	--field-bg-focus: #525252;
	--field-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
}

/* FOR TESTING */
/* .dark-theme {
	--bg: #000000;
	--border: #ff0000;
	--card-header: #00ff00;
	--card-body: #fcda9b;

	--text: #ff00f2;
	--text-secondary: #ff7af8;

	--header-text: #e2e058;
	--header-text-hover: #e2e058;
	--header-text-secondary: #47be3d;
	--header-text-secondary-hover: #2f7928;

	--accent: #ff0000;
	--accent-hover: #bb0000;
	--accent-disabled: #ff6b6b;
	--accent-text: #ffffff;

	--button: rgb(186, 241, 33);
	--button-hover: rgb(69, 85, 26);

	--field-bg: #6ab2fa;
	--field-bg-disabled: #6ab2fa;
	--field-bg-focus: #585858;
	--field-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%)
} */

body {
	color: var(--text);
	background-color: var(--bg);
}

.card-header {
	background-color: var(--card-header);
	border-color: var(--border);
}

.btn-primary {
	background-color: var(--accent);
	border-color: var(--accent);
	color: var(--accent-text);
}

.btn-primary:hover {
	background-color: var(--accent-hover);
	border-color: var(--accent-hover);
}

.btn-primary:disabled,
.btn-primary.disabled {
	cursor: default;
	background-color: var(--accent-disabled);
	border-color: var(--accent-disabled);
}

.btn-primary:focus {
	color: var(--accent-text);
	background-color: var(--accent);
	border-color: var(--accent);
	box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
	color: var(--accent-text);
	background-color: var(--accent);
	border-color: var(--accent);
}

.btn-outline-primary {
	color: var(--accent);
	border-color: var(--accent);
	background-color: transparent;
}

.btn-outline-primary:hover {
	color: var(--accent-text);
	border-color: var(--accent);
	background-color: var(--accent);
}

.btn-outline-primary:disabled {
	border-color: var(--accent-disabled);
	background-color: transparent;
	color: var(--accent);
}

.btn-outline-primary:focus {
	color: var(--accent-text);
	background-color: var(--accent);
	border-color: var(--accent);
	box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
	color: var(--accent-text);
	background-color: var(--accent);
	border-color: var(--accent);
}

a {
	color: var(--accent);
}

a:hover {
	color: var(--accent-hover);
}

.card {
	background-color: var(--card-body);
	border-color: var(--border);
}

.progress-bar {
	background-color: var(--accent);
}

.text-muted {
	color: var(--text-secondary) !important;
}

.navbar-dark {
	background-color: var(--card-header) !important;
}

.navbar-toggler {
	background-color: var(--card-header);
	border-color: var(--text) !important;
}

.light-theme .navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24,26,28,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.dark-theme .navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-dark .navbar-brand {
	color: var(--header-text);
}

.navbar-dark .navbar-brand:hover {
	color: var(--header-text-hover);
}

.navbar-dark .navbar-nav .nav-link {
	color: var(--header-text-secondary)
}

.navbar-dark .navbar-nav .nav-link:hover {
	color: var(--header-text-secondary-hover)
}

.table {
	color: var(--text);
}

.table thead th {
	border-bottom-color: var(--border);
	border-bottom-width: 1px;
}

.table td,
.table th {
	border-top-color: var(--border);
}

.page-link {
	color: var(--accent);
	background-color: var(--button);
	border-color: var(--border);
}

.page-link:hover {
	color: var(--accent-hover);
	border-color: var(--border);
	background-color: var(--button-hover);
}

.page-link:focus {
	box-shadow: none;
}

.page-item.active .page-link {
	color: var(--accent-text);
	background-color: var(--accent);
	border-color: var(--accent);
	box-shadow: none;
}

.page-item.disabled .page-link {
	background-color: var(--button);
	border-color: var(--border);
	color: var(--accent-disabled);
}

.dropdown-menu {
	background-color: var(--button);
	border-color: var(--border);
}

.dropdown-item {
	color: var(--text);
}

.dropdown-item:hover {
	color: var(--text);
	background-color: var(--button-hover);
}

.form-control {
	color: var(--text);
	border-color: var(--border);
	background-color: var(--field-bg);
}

.form-control:focus {
	color: var(--text);
	background-color: var(--field-bg-focus);
	border-color: var(--border);
	box-shadow: var(--field-shadow);
}

.form-control:disabled {
	background-color: var(--field-bg-disabled);
}

.form-check-input {
	background-color: var(--accent);
}

.modal-content {
	background-color: var(--card-body);
}

.modal-header {
	background-color: var(--card-header);
	border-color: var(--border);
}

.modal-footer {
	background-color: var(--card-header);
	border-color: var(--border);
}

.border-bottom,
.border-top {
	border-color: var(--border) !important;
}

.input-group-text {
	color: var(--text-secondary);
	background-color: var(--button);
	border-color: var(--border);
}

.jumbotron {
	background-color: var(--jumbotron-body);
}

hr {
	border-color: var(--border);
}

.dark-theme input[type="time"]::-webkit-calendar-picker-indicator {
	filter: invert(100%)
}

.dark-theme [type="date"]::-webkit-calendar-picker-indicator {
	filter: invert(100%)
}

/* End Color Scheme Stuff */


















/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.remove-border>tbody:nth-child(1)>tr:nth-child(1)>td {
	border-top: 0px;
}

.remove-ul-margin>ul {
	margin-bottom: 0px;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	display: block;
	background-color: var(--card-header);
	border-top: 1px solid var(--border);
}

.close {
	color: red;
	text-shadow: none;
}

.close:hover {
	color: red;
	text-shadow: none;
}

.sm-table-col {
	display: none;
}

.lg-table-col {
	display: table-cell;
}

.pager-center {
	margin-left: 1rem !important;
	margin-right: auto !important;
}

.card-button {
	border: 0px;
}

@media screen and (max-width: 995px) {

	/* TODO Remove this probably */
	.sm-table-col {
		display: inline;
	}

	.lg-table-col {
		display: none;
	}

	.show-small {
		display: initial !important;
	}

	.hide-small {
		display: none !important;
	}

	.small-cond {
		font-size: 80%;
		font-weight: 400;
	}

	.text-muted-cond {
		color: var(--text-secondary) !important
	}

	.pager-center {
		margin-left: auto !important;
		margin-right: auto !important;
	}
}